<template lang="html">
  <span v-if="value">
    {{ $i18n.format(value) }} {{ $i18n.translateUnit('kg/ha') }}
  </span>
  <span v-else>
    -
  </span>
</template>

<script>
export default {
  props: {
    value: Number
  }
}
</script>

<style lang="scss" scoped>
</style>
