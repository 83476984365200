<template lang="html">
  <FrsModuleRoot
    :module="module"
    :module-path="['fieldRecordSystem', 'nutrientRequirements']"
    :translation-namespaces="['nutrientRequirements']"
    :setup="loadPrerequisiteData"
  >
    <NutrientRequirements />
  </FrsModuleRoot>
</template>

<script>
import {mapActions} from 'vuex'

import FrsModuleRoot from '@frs/components/base/FrsModuleRoot'

import NutrientRequirements from './NutrientRequirements'

export default {
  components: {
    FrsModuleRoot,
    NutrientRequirements
  },
  computed: {
    module () {
      return () => import('@store/modules/field-record-system/nutrient-requirements')
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/nutrientRequirements', [
      'loadPrerequisiteData'
    ])
  }
}
</script>

<style lang="scss" scoped>
</style>
