<template lang="html">
  <div class="nutrient-requirements">
    <header>
      <h1>
        <IxRes>nutrientRequirements.headers.overview</IxRes>
      </h1>
      <div>
        <IxButton large back @click="$router.push({name: 'fieldDashboard'})">
          <IxRes>nutrientRequirements.buttons.backToFieldDashboard</IxRes>
        </IxButton>
      </div>
    </header>

    <HelpBox large>
      <IxRes>nutrientRequirements.hints.overview</IxRes>
    </HelpBox>

    <div class="table-wrapper">
      <NutrientRequirementsTable
        :nutrient-requirements="nutrientRequirements"
        :defaults="manualDefaults"
        @save="tryToSaveManualNutrientRequirement"
      />

      <FrsLoadingIndicator :requests="['nutrientRequirements.save']" />
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'

import {notifications} from 'src/js/infrastructure'

import IxButton from '@components/IxButton'
import HelpBox from '@components/help/HelpBox'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'

import NutrientRequirementsTable from './NutrientRequirementsTable'

export default {
  components: {
    IxButton,
    HelpBox,
    NutrientRequirementsTable,
    FrsLoadingIndicator
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState('fieldRecordSystem/nutrientRequirements', [
      'nutrientRequirements',
      'calculatedPreviousOrganicNitrogenFertilization'
    ]),
    manualDefaults () {
      return {
        previousOrganicNitrogenFertilization: this.calculatedPreviousOrganicNitrogenFertilization
      }
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/nutrientRequirements', [
      'saveManualNutrientRequirement'
    ]),
    async tryToSaveManualNutrientRequirement (nutrientRequirement) {
      try {
        await this.saveManualNutrientRequirement(nutrientRequirement)
        const notification = this.$i18n.translate('nutrientRequirements.manual.notifications.saveSuccess')
        notifications.success(notification)
      } catch (error) {
        console.error(error)
        const notification = this.$i18n.translate('nutrientRequirements.manual.notifications.saveError')
        notifications.error(notification)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.nutrient-requirements {
  height: 100%;
  padding: 0.5em;

  display: flex;
  flex-direction: column;

  header {
    margin-bottom: 1em;
  }

  .table-wrapper {
    flex: 1;
    min-height: 0;
    overflow-y: auto;

    position: relative;
    display: flex;
    flex-direction: column;
  }
}
</style>
