<template lang="html">
  <div class="nutrient-requirements-table">
    <header>Datum</header>
    <header>N-Bedarf</header>
    <header>P-Bedarf</header>

    <template v-if="nutrientRequirements.length">
      <template v-for="(row, i) in nutrientRequirements">
        <span :key="`timestamp_${i}`" class="timestamp value">
          {{ $i18n.format(row.timestamp, 'date') }}
        </span>
        <NutrientRequirementsTableRequirementValue
          :key="`nitrogen_${i}`"
          :value="row.nitrogenRequirement"
          class="nitrogen value"
        />
        <NutrientRequirementsTableRequirementValue
          :key="`phosphorus_${i}`"
          :value="row.phosphorusRequirement"
          class="phosphorus value"
        />
        <div :key="`actions_${i}`" class="actions">
          <!-- NOTE later for DBE integration -->
          <!-- <FontAwesomeIcon :icon="icons.details" /> -->
          <!-- NOTE later for removing values -->
          <!-- <FontAwesomeIcon :icon="icons.remove" /> -->
        </div>
      </template>
    </template>
    <div v-else class="empty-placeholder">
      <IxRes>nutrientRequirements.manual.hints.noData</IxRes>
    </div>

    <template v-if="manualRequirement">
      <VueDateTimePicker
        v-model="manualRequirement.timestamp"
        name="timestamp" class="timestamp-editor"
        iso format="L"
        required
      />
      <NumericInput
        v-model="manualRequirement.nitrogenRequirement"
        name="nitrogen" class="nitrogen-editor"
        unit="kg/ha"
      />
      <NumericInput
        v-model="manualRequirement.phosphorusRequirement"
        name="phosphorus" class="phosphorus-editor"
        unit="kg/ha"
      />
      <div class="actions">
        <FontAwesomeIcon class="save" :icon="icons.save" @click="save" />
        <FontAwesomeIcon class="cancel" :icon="icons.cancel" @click="cancel" />
      </div>
    </template>
    <a v-else href="#" @click.prevent="add">
      <IxRes>nutrientRequirements.manual.buttons.addNew</IxRes>
    </a>

    <div class="nrc-integration">
      <wip>
        <div>
          <IxButton large>
            <IxRes>nutrientRequirements.buttons.startNutrientRequirementCalculation</IxRes>
          </IxButton>
        </div>
      </wip>
    </div>
  </div>
</template>

<script>
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faCheckCircle, faTrash} from '@fortawesome/free-solid-svg-icons'

import NumericInput from '@components/forms/NumericInput'
import VueDateTimePicker from '@components/forms/VueDateTimePicker'
import IxButton from '@components/IxButton'

import NutrientRequirementsTableRequirementValue from './NutrientRequirementsTableRequirementValue'

import FormPartMixin from '@components/forms/FormPartMixin'

export default {
  components: {
    NutrientRequirementsTableRequirementValue,
    FontAwesomeIcon,
    NumericInput,
    VueDateTimePicker,
    IxButton
  },
  mixins: [
    FormPartMixin
  ],
  props: {
    nutrientRequirements: Array,
    defaults: Object
  },
  data () {
    return {
      manualRequirement: null
    }
  },
  computed: {
    icons () {
      return {
        save: faCheckCircle,
        cancel: faTrash
      }
    }
  },
  methods: {
    add () {
      this.manualRequirement = Object.assign({
        timestamp: null,
        nitrogenRequirement: null,
        phosphorusRequirement: null
      }, this.defaults)
    },
    unlock () {
      this.previousLocked = false
    },
    async save () {
      // TODO validate that at least one value is filled, both empty makes no sense
      this.validate()

      await this.$nextTick()

      if (this.state !== 'success') return

      this.$emit('save', this.manualRequirement)
      this.manualRequirement = null
    },
    cancel () {
      this.manualRequirement = null
    }
  }
}
</script>

<style lang="scss" scoped>
.nutrient-requirements-table {
  display: grid;
  grid-template-columns: repeat(3, 1fr) 0.5fr;
  gap: 0 1em;

  > header {
    font-weight: bold;
  }

  > .value {
    margin: 0.5em 0;
  }

  > .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    font-size: 1.2em;

    > * {
      margin: 0 0.25em;
      cursor: pointer;

      &.save {
        color: rgb(12, 147, 17);
      }

      &.cancel {
        color: rgb(147, 36, 12);
      }
    }
  }

  a, .empty-placeholder, .nrc-integration {
    grid-column: 1 / 5
  }

  .empty-placeholder {
    display: flex;
    justify-content: center;
    font-size: 1.4em;
    margin: 0.3em 0;
    opacity: 0.5;
  }

  .timestamp, .timestamp-editor {
    grid-column-start: 1;
  }
}
</style>
